import React from 'react';
import {
  AppLayout,
  Container,
  ContentLayout,
  Header,
} from '@cloudscape-design/components';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';
import ContentTable from './ContentTable';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import TopNav from '../components/TopNav';
import TopNavigation from '@cloudscape-design/components/top-navigation';
import { signOut } from 'aws-amplify/auth';

const LOCALE = 'en';

export default function () {
    const handleSignOut = () => {
        console.log('signing out button clicked..')
        signOut(); // Call the signOut function passed as a prop
        };
  return (
    <Authenticator>
        {({signOut, user}) => (
            <I18nProvider locale={LOCALE} messages={[messages]}>
                <TopNav></TopNav>
                <AppLayout
                    navigationHide
                    headerSelector='#h'
                    content={
                    <ContentLayout
                        header={
                        <Header variant="h1">
                            Welcome to TensorIoT Demos
                        </Header>
                        }
                    >
                        <Container
                        header={
                            <Header variant="h2" description="Below is a list of Demos. Select a demo you would like to view.">
                            Demos
                            </Header>
                        }
                        >
                        <div className="content">
                        <ContentTable ></ContentTable>
                        </div>
                        </Container>
                        
                    </ContentLayout>
                    }
                />
            </I18nProvider>
        )}
    </Authenticator>
    
  );
}