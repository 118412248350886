import '@cloudscape-design/global-styles/index.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Test from './pages/Test';
import Home from './pages/Home';


function App() {
  return (
    <div className="App">
        <Router>
          <Routes>
            <Route path='/' element={<Home />}></Route>
          </Routes>
        </Router>
    </div>
  );
}

export default App;
