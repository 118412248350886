import * as React from "react";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { signOut } from "aws-amplify/auth";

const TopNav = ({ user }) => {
    const handleSignOut = () => {
        console.log('signing out button clicked..')
        signOut(); // Call the signOut function passed as a prop
        };
    return (
    <TopNavigation
      identity={{
        href: "#",
        title: "TensorIoT Demo Repository"
        }
      }
      utilities={[
        {
          type: "menu-dropdown",
          text: user?.username || "User",
          description: user?.email || "",
          iconName: "user-profile",
          items: [
            { tyep: "button", id: "signout", text: "Sign out" }
          ],
          onItemClick: handleSignOut
        }
      ]}
    />
  );
}

export default withAuthenticator(TopNav);