import * as React from "react";
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import Link from '@cloudscape-design/components/link';
import Popover from "@cloudscape-design/components/popover";
import StatusIndicator from "@cloudscape-design/components/status-indicator";


const ContentTable = () => {

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => console.log("Copied to clipboard!"))
      .catch(err => console.error("Failed to copy: ", err));
  };

  // State to store filter text
  const [filterText, setFilterText] = React.useState("");

  // Function to handle filter text change
  const handleFilterChange = ({ detail }) => {
    setFilterText(detail.filteringText);
  };

  // Original list of items
  const allItems = [
    {
      demoName: "Amazon Q for Business",
      link: "https://yip7gzf2.chat.qbusiness.us-east-1.on.aws/#/chat",
      username: "N/A (Single Sign On)",
      password: "N/A",
      description: "Amazon Q is a cutting-edge generative AI chatbot leveraging RAG developed by AWS.",
      size: "Small",
      filterTags: "Generative AI, Bedrock, RAG, GenAI, Gen AI, Chatbot"
    },
    {
      demoName: "Post Call Analytics",
      link: "https://d8eim5auxe890.cloudfront.net/",
      username: "demo",
      password: "tensoriot@2023!",
      description: "Call Sentiment Analysis, Summarization and Q&A with Amazon Bedrock.",
      size: "Small",
      filterTags: "Generative AI, Bedrock, Sentiment Analysis, Contact Center"
    },
    {
      demoName: "SmartInsights - Job Site Analytics",
      link: "https://www.figma.com/proto/cz8hlyJwWm7OWtV9HasyHY/Archived---2021-TensorIoT-Design-System?node-id=546-2&scaling=min-zoom&page-id=536%3A666",
      username: "N/A",
      password: "N/A",
      description: "Vehicle Detection, Food Safety, Occupancy Tracking, Order Accuracy, Facility Cleaning",
      size: "Small",
      filterTags: "Vehicle Detection, Food Safety, Occupancy Tracking, Order Accuracy, Facility Cleaning"
    },
    {
      demoName: "Generic Device Dashboard",
      link: "https://xd.adobe.com/view/73aff17c-8edb-4403-78f0-bff6950c6563-059e/?fullscreen",
      username: "N/A",
      password: "N/A",
      description: "Wireframes for an IoT Analytics Dashboard",
      size: "Small",
      filterTags: "IoT, Dashboard, Analytics, Wireframes"
    },
    {
      demoName: "SmartInsights - IoT Analytics",
      link: "https://smartinsights-demo.com/",
      username: "awsviewer@tensoriot.com",
      password: "Demo123$",
      description: "IoT Analytics, Asset Map, Device Health, Anomaly Detection",
      size: "Small",
      filterTags: "IoT, Analytics, Asset Mapping, Health Monitoring, Anomaly Detection"
    },
    {
      demoName: "Driver License Detection",
      link: "https://d1fmcex6jwmk0n.cloudfront.net/",
      username: "N/A",
      password: "N/A",
      description: "Leverage Amazon Rekognition to scan driver's license and compare to human.",
      size: "Small",
      filterTags: "Amazon Rekognition, Identity Verification, Driver License Scanning, CV, OCR"
    },
    {
      demoName: "Distracted Driver Video",
      link: "https://www.dropbox.com/s/atqn1h39q2pwjuk/special-proj-market-v4.mp4?dl=0",
      username: "N/A",
      password: "N/A",
      description: "Detect whether a driver is distracted while driving.",
      size: "Small",
      filterTags: "Driver Safety, Distraction Detection, Video Analysis, CV, OCR"
    },
    {
      demoName: "Fleet Management",
      link: "https://d2xoautnqn7i25.cloudfront.net/",
      username: "demo",
      password: "Tensor123!",
      description: "Fleet mapping, vehicle routes, object detection with streaming video.",
      size: "Small",
      filterTags: "Fleet Management, Route Optimization, Object Detection, Streaming Video"
    },
    {
      demoName: "TensorInsights",
      link: "https://demo.internal.tensorinsights.ai/",
      username: "genaidemo@tensoriot.com",
      password: "B+8s,n6A2g%/s/^1",
      description: "Generative AI using Bedrock to conduct retrieval augmented generation against structured and unstructured data sources.",
      size: "Small",
      filterTags: "Generative AI, Amazon Bedrock, AI/ML, Dashboard, frontend, user interface (UI), Text Extraction"
    }
  ];  

  const filteredItems = allItems.filter(item =>
    item.demoName.toLowerCase().includes(filterText.toLowerCase()) ||
    item.username.toLowerCase().includes(filterText.toLowerCase()) ||
    item.password.toLowerCase().includes(filterText.toLowerCase()) ||
    item.description.toLowerCase().includes(filterText.toLowerCase()) ||
    item.filterTags.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <Table
      columnDefinitions={[
        {
          id: "demoName",
          header: "Demo Name",
          cell: item => <Link target="_blank" href={item.link}>{item.demoName}</Link>,
          sortingField: "demoName",
          isRowHeader: true
        },
        {
          id: "username",
          header: "User Name",
          cell: item => (
            <div>
              {item.username}
              {item.username !== "N/A" && (
                <Popover
                  dismissButton={false}
                  position="top"
                  size="small"
                  triggerType="custom"
                  content={
                    <StatusIndicator type="success">
                      Username Copied!
                    </StatusIndicator>
                  }
                  >
                  <Button
                    iconName="copy"
                    variant="inline-icon" 
                    onClick={() => handleCopy(item.username)}
                  />
                </Popover>
              )}
            </div>
          )
        },
        {
          id: "password",
          header: "Password",
          cell: item => (
            <div>
              {item.password == "N/A" ? item.password : item.password.replace(/./g, '*')}
              {item.password !== "N/A" && (
                <Popover
                  dismissButton={false}
                  position="top"
                  size="small"
                  triggerType="custom"
                  content={
                    <StatusIndicator type="success">
                      Password Copied!
                    </StatusIndicator>
                  }
                  >
                  <Button
                    iconName="copy"
                    variant="inline-icon"
                    onClick={() => handleCopy(item.password)}
                  />
                </Popover>
              )}
              
            </div>
          )
        },
        {
          id: "description",
          header: "Description",
          cell: item => item.description
        }
      ]}
      columnDisplay={[
        { id: "demoName", visible: true },
        { id: "username", visible: true },
        { id: "password", visible: true },
        { id: "description", visible: true }
      ]}
      items={filteredItems}
      loadingText="Loading resources"
      trackBy="name"
      empty={
        <Box
          margin={{ vertical: "xs" }}
          textAlign="center"
          color="inherit"
        >
          <SpaceBetween size="m">
            <b>No resources</b>
            <Button>Create resource</Button>
          </SpaceBetween>
        </Box>
      }
      filter={
        <TextFilter
          filteringPlaceholder="Find Demos"
          filteringText={filterText}
          onChange={handleFilterChange}
        />
      }
      header={
        <Header>
          TensorIoT Demo Repository
        </Header>
      }
      pagination={
        <Pagination currentPageIndex={1} pagesCount={2} />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={{
            pageSize: 10,
            contentDisplay: [
              { id: "variable", visible: true },
              { id: "value", visible: true },
              { id: "type", visible: true },
              { id: "description", visible: true }
            ]
          }}
          pageSizePreference={{
            title: "Page size",
            options: [
              { value: 10, label: "10 resources" },
              { value: 20, label: "20 resources" }
            ]
          }}
          wrapLinesPreference={{}}
          stripedRowsPreference={{}}
          contentDensityPreference={{}}
          contentDisplayPreference={{
            options: [
              {
                id: "demoName",
                label: "Demo Name",
                alwaysVisible: true
              },
              { id: "userName", label: "User Name" },
              { id: "password", label: "Password" },
              { id: "description", label: "Description" }
            ]
          }}
          stickyColumnsPreference={{
            firstColumns: {
              title: "Stick first column(s)",
              description:
                "Keep the first column(s) visible while horizontally scrolling the table content.",
              options: [
                { label: "None", value: 0 },
                { label: "First column", value: 1 },
                { label: "First two columns", value: 2 }
              ]
            },
            lastColumns: {
              title: "Stick last column",
              description:
                "Keep the last column visible while horizontally scrolling the table content.",
              options: [
                { label: "None", value: 0 },
                { label: "Last column", value: 1 }
              ]
            }
          }}
        />
      }
    />
  );
}

export default ContentTable;